import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const SectionHeading = ({
  bgColor,
  center,
  className: _className,
  heading,
  order,
}) => {
  return (
    <section
      className={classNames(
        "relative z-20",
        {
          "py-16 md:py-32": order !== 0,
          "pt-32 md:pt-48": order === 0,
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white" || !bgColor,
          "bg-carnation": bgColor === "carnation",
          "bg-mint": bgColor === "mint",
        },
        _className
      )}
    >
      <Container variant="xs">
        <Text
          variant="h1"
          className={classNames({
            "text-center": center,
            "text-white": bgColor === "midnight",
          })}
        >
          {heading}
        </Text>
      </Container>
    </section>
  );
};

SectionHeading.defaultProps = {};

export default SectionHeading;
